export var GET_METHOD = {

  GET_COUNTRY_LIST: '/api/admin/get_country_list',
  GET_ALL_COUNTRY: '/admin/get_country_list',
  GET_ADMIN_COUNTRY_LIST: "/admin/country_list",
  GET_SERVER_COUNTRY_LIST: "/admin/get_server_country_list",
  GET_TIMEZONE_LIST: '/admin/get_timezone_list',
  VEHICLE_LIST: '/admin/vehicle_list',
  ALL_DELIVERY_LIST: 'admin/delivery_list',
  ADMIN_LIST: '/admin/lists',
  ADMIN_ROLES_LIST: '/admin/admin_role_lists',
  GET_DELIVERY_LIST: '/api/admin/get_delivery_list',
  GET_APP_NAME: '/admin/get_app_name',
  GET_LANGUAGES: '/admin/get_languages',
  GET_PROVIDERS: '/admin/get_providers',
}

export var METHODS = {

  /////////////AMY APIS
  GET_PRIVACY_POLICY: '/admin/get_privacy_policy',
  SET_PRIVACY_POLICY: '/admin/set_privacy_policy',

  ADMIN_NATION_LIST: '/admin/admin_nation_list',
  ADMIN_HUMAN_SKIN_COLOR_LIST: '/admin/admin_human_skin_color_list',
  ADMIN_BLOOD_GROUP_LIST: '/admin/admin_blood_group_list',

  ADD_PROVINCE_DATA: '/admin/add_province_data',
  EDIT_PROVINCE_DATA: '/admin/edit_province_data',
  GET_PROVINCE_DATA : '/admin/get_province_data',
  GET_ALL_PROVINCE_DATA : '/admin/get_all_province_data',
  GET_ALL_ACTIVE_PROVINCE_DATA : '/admin/get_all_active_province_data',

  IMPORT_PROVINCE_DATA : '/admin/import_province_data',


  PATIENT_LIST: '/admin/get_patients_list',
  GET_PATIENT_DETAIL: '/admin/get_patient_detail',

  GET_ALL_PATIENT_LIST: '/admin/get_all_patients_list',

  ADD_EDIT_GROUP_DATA: '/admin/add_edit_group_details',
  GROUP_LIST: '/admin/get_group_details',
  DELETE_GROUP: '/admin/delete_group_details',



  ADD_DISTRICT_DATA: '/admin/add_district_data',
  EDIT_DISTRICT_DATA: '/admin/edit_district_data',
  GET_DISTRICT_DATA : '/admin/get_district_data',
  GET_DISTRICT_DATA_FROM_PROVINCE : '/admin/get_district_data_from_province',
  GET_ALL_DISTRICT_DATA : '/admin/get_all_district_data',
  GET_ALL_ACTIVE_DISTRICT_DATA : '/admin/get_all_active_district_data',

  IMPORT_DISTRICT_DATA : '/admin/import_district_data',

  GET_ALL_DESEASE: '/admin/get_all_decease',

  GET_ALL_ADMIT: '/admin/get_all_admit',

  GET_ALL_WARD: '/admin/get_all_ward',



  ADD_SUB_DISTRICT_DATA: '/admin/add_sub_district_data',
  EDIT_SUB_DISTRICT_DATA: '/admin/edit_sub_district_data',
  GET_SUB_DISTRICT_DATA : '/admin/get_sub_district_data',
  GET_ALL_SUB_DISTRICT_DATA : '/admin/get_all_sub_district_data',
  GET_ALL_ACTIVE_SUB_DISTRICT_DATA : '/admin/get_all_active_sub_district_data',

  IMPORT_SUB_DISTRICT_DATA : '/admin/import_sub_district_data',



  ADD_HOSPITAL_DATA: '/admin/add_hospital_data',
  EDIT_HOSPITAL_DATA: '/admin/edit_hospital_data',
  GET_HOSPITAL_DATA : '/admin/get_hospital_data',
  IMPORT_HOSPITAL_DATA : '/admin/import_hospital_data',

  GET_NATION_DATA : '/admin/get_nation_data',
  IMPORT_NATION_DATA : '/admin/import_nation_data',
  ADD_NATION_DATA : '/admin/add_nation_data',
  EDIT_NATION_DATA: '/admin/edit_nation_data',



  GET_HUMAN_SKIN_COLOR_DATA : '/admin/get_human_skin_color_data',
  IMPORT_HUMAN_SKIN_COLOR_DATA : '/admin/import_human_skin_color_data',
  ADD_HUMAN_SKIN_COLOR_DATA : '/admin/add_human_skin_color_data',
  EDIT_HUMAN_SKIN_COLOR_DATA: '/admin/edit_human_skin_color_data',


  GET_BLOOD_GROUP_DATA : '/admin/get_blood_group_data',
  IMPORT_BLOOD_GROUP_DATA : '/admin/import_blood_group_data',
  ADD_BLOOD_GROUP_DATA : '/admin/add_blood_group_data',
  EDIT_BLOOD_GROUP_DATA: '/admin/edit_blood_group_data',


  GET_VOIP_USER_DATA : '/admin/get_voip_user_data',
  IMPORT_VOIP_USER_DATA : '/admin/import_voip_user_data',
  ADD_VOIP_USER_DATA : '/admin/add_voip_user_data',
  EDIT_VOIP_USER_DATA: '/admin/edit_voip_user_data',

  GET_STAFF_TYPE : '/admin/get_staff_type',
  ADD_STAFF_TYPE : '/admin/add_staff_type',
  EDIT_STAFF_TYPE: '/admin/edit_staff_type',
  GET_ALL_STAFF_TYPE : '/admin/get_all_staff_type',

  GET_UNDERLYING_DISEASE : '/admin/get_underlying_disease',
  ADD_UNDERLYING_DISEASE : '/admin/add_underlying_disease',
  EDIT_UNDERLYING_DISEASE: '/admin/edit_underlying_disease',

  GET_AVA_BOX : '/admin/get_ava_box',
  ADD_AVA_BOX : '/admin/add_ava_box',
  EDIT_AVA_BOX: '/admin/edit_ava_box',

  GET_WATCH : '/admin/get_Watch',
  ADD_WATCH : '/admin/add_Watch',
  EDIT_WATCH: '/admin/edit_Watch',
  
  GET_WARD : '/admin/get_ward',
  ADD_WARD : '/admin/add_ward',
  EDIT_WARD: '/admin/edit_ward',

  GET_MEDICAL_DEVICES : '/admin/get_medical_devices',
  ADD_MEDICAL_DEVICES : '/admin/add_medical_devices',
  EDIT_MEDICAL_DEVICES: '/admin/edit_medical_devices',

  GET_MOBILE_OPERATOR : '/admin/get_mobile_operator',
  ADD_MOBILE_OPERATOR : '/admin/add_mobile_operator',
  EDIT_MOBILE_OPERATOR: '/admin/edit_mobile_operator',


  GET_AMY_BOX_LIST_DATA : '/admin/get_amy_box_list_data',
  ADD_AMY_BOX_DATA : '/admin/add_amy_box_data',
  EDIT_AMY_BOX_DATA: '/admin/edit_amy_box_data',
  DELETE_AMY_BOX: '/admin/delete_amy_box',

  GET_AMY_DEVICE_LIST_DATA : '/admin/get_amy_device_list_data',
  ADD_AMY_DEVICE_DATA : '/admin/add_amy_device_data',
  EDIT_AMY_DEVICE_DATA: '/admin/edit_amy_device_data',
  GET_AMY_BOX_TRANSACTION : '/admin/get_amy_box_transaction',
  GET_AMY_SENSOR_LIST : '/admin/get_amy_sensor_list',
  EDIT_AMY_SENSOR_DATA: '/admin/edit_amy_sensor_data',
  ADD_AMY_SENSOR_DATA : '/admin/add_amy_sensor_data',
  GET_AMY_SENSOR_TRANSACTION : '/admin/get_amy_sensor_transaction',
  GET_ESP32_TRANSACTION : '/admin/get_esp32_transaction',
  GET_MFC_TRANSACTION : '/admin/get_mfc_transaction',

  GET_NOTIFY_TRANSACTION : '/admin/get_notify_transaction',

  DELETE_AMY_DEVICE_DATA: '/admin/delete_amy_device_data',

  CHECK_BOX_STATUS : '/admin/check_box_status',
  REBOOT_BOX : '/admin/reboot_box',

  GET_AMY_BOX_LIST : '/admin/get_amy_box_list',
  CHECK_BOX_SENSOR_STATUS : '/admin/check_box_sensor_status',
  DELETE_SENSORS : '/admin/delete_sensors',

  CHECK_AMY_DEVICE_MAC : '/admin/check_amy_device_mac',
  GET_AMY_WATCH_DEBUG_TRANSACTION : '/admin/get_amy_watch_debug_transaction',
  GET_AMY_WATCH_TRANSACTION : '/admin/get_amy_watch_transaction',
  UPDATE_AMY_WATCH_TRANSACTION : '/admin/update_amy_watch_transaction',

  UPDATE_NOTIFY : '/admin/update_notify',

  GET_NOTIFY : '/admin/get_notify',

  ADD_WATCH_DATA : '/admin/add_watch_data',
  EDIT_WATCH_DATA : '/admin/edit_watch_data',
  GET_WATCH_LIST_DATA : '/admin/get_watch_list_data',
  DELETE_WATCH_DATA: '/admin/delete_watch_data',


  GET_PATIENT_LIST_FOR_MAP: "/admin/get_patient_list_for_map",
  GET_WATCH_PATIENT_LIST_FOR_MAP: "/admin/get_watch_patient_list_for_map",

  GET_WATCH_PUSH_NOTIFICATION : '/admin/get_watch_push_notification',

  GET_TCP_WATCH_DEBUG_TRANSACTION : '/admin/get_tcp_watch_debug_transaction',
  GET_AMY_WATCH_LOCATION_TRANSACTION : '/admin/get_amy_watch_location_transaction',
  GET_ADDRESS : '/admin/get_address',

  WATCH_COMMAND : '/admin/watch_command',

  GET_MFC_HV01_BOX_LIST_DATA : '/admin/get_mfc_hv01_box_list_data',
  ADD_MFC_HV01_BOX_DATA : '/admin/add_mfc_hv01_box_data',
  EDIT_MFC_HV01_BOX_DATA: '/admin/edit_mfc_hv01_box_data',
  DELETE_MFC_HV01_BOX: '/admin/delete_mfc_hv01_box',

  ////////////////







  SUB_STORE_LOGIN: '/api/store/sub_store_login',
  LOGIN: '/api/store/login',
  LOGOUT: '/api/store/logout',
  REGISTER: '/api/store/register',
  UPDATE: '/api/store/update',
  GET_STORE_DATA: '/api/store/get_store_data',
  LIST_REVIEWS:'/admin/admin_review_list',
  FORGOT_PASSWORD_VERIFY: '/api/admin/forgot_password_verify',
  NEW_PASSWORD: '/api/admin/new_password',
  DELETE: '/admin/delete',
  DELETE_ADMIN_ROLE: '/admin/delete_admin_role',

  ADMIN_LOGIN: '/login',
  LIST_LANGUAGE:'/admin/get_languages',
  USER_LIST_SEARCH_SORT: '/admin/user_list_search_sort',
  APPROVE_DECLINE_USER: '/admin/approve_decline_user',

  DELETE_USER: '/admin/delete_user',

  GET_USER_DETAIL: '/admin/get_user_detail',
  UPDATE_USER: '/admin/update_user',
  ADD_USER: '/admin/add_user',
  ADD_WALLET: '/admin/add_wallet',
  SEND_SMS: '/admin/send_sms',
  SEND_NOTIFICATION: '/admin/send_notification',
  VIEW_DOCUMENT_LIST: '/admin/view_document_list',
  GET_REFERRAL_HISTORY: '/admin/get_user_referral_history',
  GET_USER_REVIEW_HISTORY:   '/admin/get_user_review_history',
  UPLOAD_DOCUMENT: '/admin/upload_document',
  PROVIDER_LIST_SEARCH_SORT: '/api/provider/provider_list_search_sort',
  APPROVE_DECLINE_PROVIDER: '/admin/provider_approve_decline',
  GET_PROVIDER_DETAIL: '/admin/get_admin_provider_detail',
  UPDATE_PROVIDER: '/admin/update_provider',
  GET_PROVIDER_REVIEW_HISTORY:   '/admin/get_provider_review_history',

  HOSPITAL_USER_LIST_SEARCH_SORT: '/admin/hospital_user_list_search_sort',
  APPROVE_DECLINE_HOSPITAL_USER: '/admin/approve_decline_hospital_user',
  GET_HOSPITAL_USER_DETAIL: '/admin/get_hospital_user_detail',
  UPDATE_HOSPITAL_USER: '/admin/update_hospital_user',
  ADD_HOSPITAL_USER: '/admin/add_hospital_user',

  STORE_LIST_SEARCH_SORT: '/admin/store_list_search_sort',
  APPROVE_DECLINE_STORE: '/admin/approve_decline_store',
  GET_STORE_DETAIL: '/admin/get_admin_store_detail',
  UPDATE_STORE: '/admin/update_store',
  GET_STORE_REVIEW_HISTORY:   '/admin/get_store_review_history',
  ALL_DELIVERY_LIST: '/admin/delivery_list',
  PROMO_CODE_LIST: '/admin/promo_code_list',
  GET_PROMO_USES_DETAIL: '/admin/get_promo_uses_detail',
  ADD_PROMO: '/admin/add_promo_code_data',
  UPDATE_PROMO: '/admin/update_promo_code',
  GET_PROMO_DETAIL: '/admin/get_promo_detail',
  UPDATE_PROMO_IMAGE: '',
  CHECK_PROMO: '',
  UPDATE_SPECIFICATIONS_NAME: '/api/store/update_specification_name',
  UPDATE_SP_NAME: '/api/admin/update_sp_name',
  FORGOT_PASSWORD: '/api/admin/forgot_password',
  GET_CITY_LIST: '/api/admin/get_city_list',
  GET_STORE_LIST_BY_DELIVERY: '/admin/get_store_list_by_delivery',
  CITY_LIST: '/admin/city_list',
  GET_DISPATCHER_ORDER_LIST: "/api/store/get_dispatcher_order_list",
  GET_COUNTRY_DATA: "/admin/get_country_data",
  ADD_COUNTRY_DATA:"/admin/add_country_data",
  UPDATE_COUNTRY:"/admin/update_country",
  GET_COUNTRY_TIMEZONE:"/admin/get_country_timezone",
  ADD_CITY_DATA:"/admin/add_city_data",
  CHECK_CITY: "/admin/check_city",
  UPDATE_CITY_DATA: "/admin/update_city",
  GET_CITY_DETAIL:"/admin/get_city_detail",
  GET_WALLET_DETAIL:"/admin/get_wallet_detail",
  GET_SETTING_DETAIL: '/api/admin/get_setting_detail',
  GET_INSTALLATION_SETTING: '/api/admin/get_app_keys',
  UPDATE_ADMIN_SETTING: '/api/admin/update_admin_setting',
  UPDATE_INSTALLATION_SETTING: '/api/admin/update_installation_setting',
  COUNTRY_DETAIL_FOR_ADMIN: '/admin/country_detail_for_admin',
  UPLOAD_LOGO_IMAGES: '/admin/upload_logo_images',
  UPDATE_PUSH_NOTIFICATION_SETTING: '/admin/update_push_notification_setting',
  STORE_LIST_FOR_MAP: '/admin/store_list_for_map',
  PROVIDER_LIST_FOR_MAP: '/admin/provider_list_for_map',
  GET_MASS_NOTIFICATION_LIST: '/admin/get_mass_notification_list',
  CREATE_MASS_NOTIFICATION: '/admin/create_mass_notifications',
  SMS_LIST: '/admin/sms_list',
  EMAIL_LIST: '/admin/email_list',
  UPDATE_SMS: '/admin/update_sms',
  UPDATE_EMAIL: '/admin/update_email',
  UPDATE_EMAIL_CONFIGURATION: '/admin/update_email_configuration',
  GET_SMS_GATEWAY_DETAIL:'/admin/get_sms_gateway_detail',
  UPDATE_SMS_CONFIGURATION:'/admin/update_sms_configuration',
  GET_REFERRAL_HISTORY_LIST:'/admin/get_referral_detail',
  GET_TRANSACTION_HISTORY_LIST:'/admin/get_transaction_history',
  GET_STORE_LIST_FOR_COUNTRY:'/admin/get_store_list_for_country',
  GET_STORE_LIST_FOR_CITY:'/admin/get_store_list_for_city',
  ADD_ADVERTISE:'/admin/add_advertise',
  UPDATE_ADVERTISE:'/admin/update_advertise',
  DELETE_ADVERTISE:'/admin/delete_advertise',
  ADVERTISE_LIST:'/admin/advertise_list',
  GET_ADVERTISE_DETAIL:'/admin/get_advertise_detail',
  GET_MAIL_GATEWAY_DETAIL:'/api/admin/get_setting_detail_for_mail_config',
  GET_PROVIDER_LIST_FOR_CITY:'/admin/get_provider_list_for_city',
  GET_ORDER_LIST_FOR_LOCATION_TRACK:'/admin/order_list_location_track',
  DELIVERYMAN_TRACK:'/admin/deliveryman_track',

  GET_PRODUCT_LIST: '/api/store/get_product_list',
  GET_SOECIFICATION_LIST: '/api/store/get_specification_lists',
  ADD_ITEM: '/api/store/add_item',
  CANCEL_REQUEST: '/api/store/cancel_request',
  CREATE_REQUEST: '/api/store/create_request',
  GET_ITEM_DATA: '/api/store/get_item_data',
  GET_SPECIFICATION_LISTS: '/api/store/get_specification_lists',
  UPDATE_ITEM: '/api/store/update_item',
  UPDATE_ITEM_IMAGE: '/api/store/update_item_image',
  DELETE_ITEM_IMAGE: '/api/store/delete_item_image',
  GET_PRODUCT_DATA: '/api/store/get_product_data',
  ADD_PRODUCT_GROUP_DATA: '/api/store/add_product_group_data',
  GET_PRODUCT_GROUP_LIST: '/api/store/get_product_group_list',
  GET_PRODUCT_LIST_OF_GROUP: '/api/store/get_group_list_of_group',

  UPDATE_PRODUCT_GROUP_DATA: '/api/store/update_product_group',
  DELETE_PRODUCT_GROUP_DATA: '/api/store/delete_product_group',
  GET_PRODUCT_GROUP_DATA: '/api/store/get_product_group_data',
  UPDATE_PRODUCT: '/api/store/update_product',
  GET_STORE_PRODUCT_ITEM_LIST: '/api/store/get_store_product_item_list',
  SET_ORDER_STATUS: '/api/store/set_order_status',
  ADD_PRODUCT: '/api/store/add_product',
  GET_SPECIFICATION_GROUP: '/api/store/get_specification_group',
  ADD_SPECIFICATION_GROUP: '/api/store/add_specification_group',
  ADD_SPECIFICATION: '/api/store/add_specification',
  WEEKLY_EARNING: '/api/store/weekly_earning',
  COMPLETE_ORDER: '/api/store/complete_order',
  GET_STORE_PROVIDER_VEHICLE:'/admin/provider_vehicle_list',
  UPDATE_STORE_PROVIDER_VEHICLE:'/admin/provider_vehicle_update',
  GET_PAYMENT_GATEWAYS: '/api/store/get_payment_gateways',
  UPDATE_CASH_PAYMENT_GATEWAYS: '/api/store/update_cash_payment_gateways',
  UPDATE_PAYMENT_GATEWAYS: '/api/store/update_payment_gateways',
  PAYMENT_GATEWAY_LIST: '/admin/payment_gateway_list',
  LIST_ORDERS_HISTORY:'/api/admin/history',

  LIST_EARNING:'/admin/get_earning',
  FETCH_EARNING_DETAIL:'/admin/fetch_earning_detail',
  LIST_ORDERS:'/admin/admin_list_orders',
  FETCH_ORDER_DETAIL:'/admin/admin_fetch_order_detail',
  LIST_DELIVERIES:'/admin/admin_list_deliveries',

  LIST_STORE_EARNING: '/admin/store_weekly_earning',
  LIST_DELIVERYMAN_EARNING: '/admin/provider_weekly_earning',
  FETCH_DELIVERYMAN_EARNING: '/api/provider/weekly_earning',

  GET_CITY_LISTS: '/api/admin/get_city_lists',
  GET_VEHICLE_LIST: '/api/admin/get_vehicle_list',
  GET_ZONE_DETAIL: '/admin/get_zone_detail',
  SERVICE_LIST: '/admin/service_list',
  ADD_SERVICE_DATA: '/admin/add_service_data',
  ADD_ZONE_PRICE: '/admin/add_zone_price',
  GET_SERVICE_DETAIL: '/admin/get_service_detail',
  UPDATE_SERVICE: '/admin/update_service',
  GET_IMAGE_SETTING: '/api/admin/get_image_setting',
  UPDATE_VEHICLE: '/admin/update_vehicle',
  GET_VEHICLE_DETAIL: '/admin/get_vehicle_detail',
  ADD_VEHICLE_DATA: '/admin/add_vehicle_data',
  GET_WALLET_REQUEST_LIST_SEARCH_SORT: '/admin/get_wallet_request_list_search_sort',
  GET_WALLET_REQUEST_BANK_DETAIL: '/admin/get_wallet_request_bank_detail',
  COMPLETE_WALLETE_REQUEST_AMOUNT: '/admin/complete_wallet_request_amount',
  APPROVE_WALLET_REQUEST_AMOUNT: '/admin/approve_wallet_request_amount',
  CANCLE_WALLET_REQUEST: '/admin/cancel_wallet_request',
  TRANSFER_WALLET_REQUEST_AMOUNT: '/admin/transfer_wallet_request_amount',
  UPDATE_DELIVERY: '/admin/update_delivery',
  ADD_DELIVERY_DATA: '/admin/add_delivery_data',
  ADD_DOCUMENT_DATA: '/admin/add_document_data',
  DOCUMENT_LIST: '/admin/document_list',
  UPDATE_DOCUMENT: '/admin/update_document',
  GET_DETAILS: '/admin/get_detail',
  ADD_ADMIN: '/admin/add',
  ADD_ADMIN_ROLE: '/admin/add_admin_role',
  UPDATE_ADMIN: '/admin/update',
  UPDATE_ADMIN_ROLE: '/admin/update_admin_role',
  PRODUCT_FOR_CITY_STORE: '/admin/product_for_city_store',
  ITEM_FOR_CITY_STORE: '/admin/item_for_city_store',
  ORDER_DETAIL: '/admin/dashboard/order_detail',
  LAST_SIX_MONTH_PAYMENT_DETAIL: '/admin/dashboard/last_six_month_payment_detail',
  LAST_SIX_MONTH_EARNING_DETAIL: '/admin/dashboard/last_six_month_earning_detail',
  LAST_FIFTEEN_DAY_ORDER_DETAIL: '/admin/dashboard/last_fifteen_day_order_detail',
  MONTHLY_PAYMENT_DETAIL: '/admin/dashboard/monthly_payment_detail',

  // GET_VEHICLE_LIST_FOR_STORE: '/api/store/get_vehicle_list',
  FIND_NEAREST_PROVIDER:'/api/store/find_nearest_provider_list',
  GET_ORDER_DISPATCHER_ORDER_LIST: '/admin/get_admin_dispatcher_order_list',

  GET_STORE_VEHICLE_LIST: '/admin/get_vehicles_lists',
  USER_CANCLE_ORDER: '/api/user/user_cancel_order',
  ADD_NEW_LANGUAGE: '/admin/add_new_language',

  DELIVERY_LIST_SEARCH_SORT:'/api/admin/delivery_list_search_sort',
  ADMIN_REQUESTS_DETAIL: "/api/admin/admin_requests_detail",
  GET_DELIVERY_LIST:"/api/admin/get_delivery_list",


  ADD_NEW_STORE: '/admin/add_new_store',
  ADD_NEW_USER: '/admin/add_new_user',
  ADD_NEW_PROVIDER: '/admin/add_new_provider',
  UPDATE_DATABASE_TABLE: '/updateDatabaseTable',
  ADD_PROVIDER_VEHICLE_DATA: '/admin/add_provider_vehicle_data',
  UPDATE_ITEM_NEW_TABLE: '/updateItemNewTable',
  UPDATE_ZONE_PRICE: '/admin/update_zone_price',

  ADD_TAX: "/admin/add_tax",
  EDIT_TAX: "/admin/edit_tax",

  ADD_PATIENT_DATA: '/admin/add_patient_data',
  UPDATE_PATIENT: '/admin/update_patient_data',

  ACTIVITY_LOG_LIST_SEARCH_SORT: '/admin/activity_log_list_search_sort',

  GET_BODY_TEMPERATURE_HISTORY_DATA: '/api/user/get_body_temperature_history_data',
  GET_BODY_TEMPERATURE_GATEWAY_HISTORY_DATA: '/api/user/get_body_temperature_gateway_history_data',
  GET_BODY_TEMPERATURE_WATCH_HISTORY_DATA: '/api/user/get_body_temperature_watch_history_data',

  GET_SPO2_GATEWAY_DATA: '/api/user/get_spo2_gateway_history_data',
  GET_SPO2_WATCH_DATA: '/api/user/get_spo2_watch_history_data',
  GET_SPO2_HISTORY_DATA: '/api/user/get_spo2_history_data',
  GET_SPO2_ALL_GATEWAY_DATA: '/api/user/get_all_gateway_history_data',



  GET_BP_HISTORY_DATA: '/api/user/get_bp_history_data',
  GET_BP_GATEWAY_DATA : '/api/user/get_bp_gateway_history_data',
  GET_BP_WATCH_DATA : '/api/user/get_bp_watch_history_data',

  GET_UNDERLYING_DISEASE_HISTORY_DATA: '/api/user/get_underlying_disease_history_data',
  GET_MEDICATION_HISTORY_DATA: '/api/user/get_medication_history_data',
  GET_ALLERGY_HISTORY_DATA: '/api/user/get_allergy_history_data',

  GET_BLOOD_SUGAR_HISTORY_DATA: '/api/user/get_blood_sugar_history_data',
  GET_CREATININE_HISTORY_DATA: '/api/user/get_creatinine_history_data',
  GET_LIPID_HISTORY_DATA: '/api/user/get_lipid_history_data',

  GET_STEP_HISTORY_DATA: '/api/user/get_step_history_data',
  GET_SLEEP_HISTORY_DATA: '/api/user/get_sleep_history_data',

  GET_BODY_DATA_HISTORY_DATA: '/api/user/get_body_data_history_data',

  CHECK_AMY_MAC : '/admin/check_amy_mac',

  REQUEST_AMBULANCE_LIST: '/admin/get_request_ambulances_form',
  ADD_REQUEST_AMBULANCE: '/admin/add_request_ambulances_form',
  EDIT_REQUEST_AMBULANCE: '/admin/edit_request_ambulances_form',
  DELETE_REQUEST_AMBULANCE: '/admin/delete_request_ambulances_form',
  
  REFERRAL_AMBULANCE_OPERATION_LIST: '/admin/get_referrals_ambulance_operations_form',
  ADD_REFERRAL_AMBULANCE_OPERATION: '/admin/add_referrals_ambulance_operations_form',
  EDIT_REFERRAL_AMBULANCE_OPERATION: '/admin/edit_referrals_ambulance_operations_form',
  DELETE_REFERRAL_AMBULANCE_OPERATION: '/admin/delete_referrals_ambulance_operations_form',

  TRANSPORTATION_TREATMENT_LIST: '/admin/get_transportation_treatment_form',
  ADD_TRANSPORTATION_TREATMENT: '/admin/add_transportation_treatment_form',
  EDIT_TRANSPORTATION_TREATMENT: '/admin/edit_transportation_treatment_form',
  DELETE_TRANSPORTATION_TREATMENT: '/admin/delete_transportation_treatment_form',

  OPERATION_FORM_LIST: '/admin/get_operation_form',
  ADD_OPERATION_FORM: '/admin/add_operation_form',
  EDIT_OPERATION_FORM: '/admin/edit_operation_form',
  DELETE_OPERATION_FORM: '/admin/delete_operation_form',



}
